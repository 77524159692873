import GATSBY_COMPILED_MDX from "/Users/dani/code/d4n1b/shirty-landing/posts/privacy-policy.es.mdx";
import React from "react";
import {graphql} from "gatsby";
import {useIntl} from "react-intl";
import {Header, Footer, SEOHead} from "../components";
function LayoutWide({data, children}) {
  const intl = useIntl();
  const {frontmatter} = data.mdx;
  return React.createElement("div", {
    className: "flex flex-col min-h-screen overflow-hidden"
  }, React.createElement(SEOHead, {
    title: frontmatter.title,
    description: frontmatter.description
  }), React.createElement(Header), React.createElement("main", {
    className: "flex-grow relative w-full max-w-6xl mx-auto px-4 sm:px-6 py-24 prose lg:prose-xl"
  }, React.createElement("h1", {
    className: "text-center"
  }, frontmatter.title), frontmatter.showUpdatedAt && React.createElement("span", {
    className: "uppercase text-xs font-bold mb-5 block"
  }, intl.formatMessage({
    id: "lastUpdate"
  }), ": ", frontmatter.updatedAt), children), React.createElement(Footer));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(LayoutWide, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        id
        title
        slug
        language
        createdAt(formatString: "MMM DD, YYYY")
        updatedAt(formatString: "MMM DD, YYYY")
        showUpdatedAt
      }
    }
  }
`;
